<!--
* @description:
* @fileName tpl-banner.vue
* @author hvv
* @date 2022/01/11 13:13:31
!-->
<template>
  <div class="layout">
    <el-carousel height="150px">
      <el-carousel-item v-for="item in res.list" :key="item">
        <!-- <h3 class="small">{{  }}</h3> -->
        <el-image
          v-if="!item.extraParams || !item.extraParams.isVideo"
          :fit="fit"
          :src="item.img"
          style="width: 100%; height: 100%"
        />

        <video
          v-else
          controls
          :src="item.extraParams.videoSrc"
          style="width: 100%; height: 100%"
        ></video>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  import {
    defineAsyncComponent,
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
    watch,
  } from 'vue'
  export default defineComponent({
    name: 'TplBanner',
    props: {
      res: Object,
    },
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance

      const state = reactive({
        nowItem: {},
        fit: 'fill',
      })
      onMounted(() => {
        // proxy.nowItem = props.res

        console.log(props.res)
      })
      watch(
        () => props.res,
        (newVal, oldVal) => {
          console.log(newVal, oldVal, '123123123')
        }
      )

      return {
        ...toRefs(state),
      }
    },
  })
</script>
<style lang="scss" scoped>
  @import './tpl.scss';
</style>
